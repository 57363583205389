<template>
    <div class="container_warp">
      <div class="warp-content">
        <div class="content-menu" v-loading="treeLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="menu-list">
            <div v-if="projectList.length>0">
              <div class="tree-menu" v-for="(item,index) in projectList" :key="index">
                <div class="tree-menu-title" @click.prevent="projectToggle(item)">
                  <div>
                    <span class="icon-triangle" :class="item.disable?'arrow':'unarrow'"></span>
                    <span>{{item.name}}</span>
                  </div>
                  <div>
                    <span class="flag flag-allow" v-show="item.authority=='operate'">允许</span>
                    <span class="flag flag-onlyread" v-show="item.authority=='read'">只读</span>
                  </div>
                </div>
                <div class="tree-config" v-if="item.disable">
                  <div v-if="item.configList&&item.configList.length>0">
                    <div v-for="(val,key) in item.configList" :key="key">
                      <div class="config-title" :class="current==val.configurationId?'bright':''" @click="selectConfig(val,item)">
                        <span class="class-icon iconfont" 
                          :class="val.name=='历史记录'?'icon-jilu':val.name=='告警记录'?'icon-jilu':val.name=='操作记录'?'icon-jilu':'icon-beijianleixing-'" 
                          :style="current==val.configurationId?'color:#FFF;':'color:#89A3BE;'"></span>
                        <span>{{val.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else style="width: 100%;text-align: center;line-height: 40px;font-size: 14px;color: #909399;">暂无数据</div>
                </div>
              </div>
            </div>
            <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
          </div>
          <div class="fix-offline-device">
            <div class="offline-device-count">
              <span class="device-count-name">离线设备数量</span>
              <span class="device-count-value" :style="showDeviceOfflines.length>9?'width:30px;':''">{{ showDeviceOfflines.length }}</span>
            </div>
            <div class="offline-device-list" v-if="showDeviceOfflines.length>0">
              <div class="device-list-name">离线设备列表</div>
              <div class="device-list-value">
                <div class="device-list-item" v-for="(item,index) in showDeviceOfflines" :key="index">{{ item.equipmentName ? item.equipmentName : '-' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="conName!='历史记录'&&conName!='告警记录'&&conName!='操作记录'" class="content-config tab-pane-bg">
          <div v-if="row">
            <div :id="'containerDetailChart'+editableTabsValue" class="config-container"></div>
            <div class="model-mask" v-show="isModelMask"></div>
          </div>
          <div v-else class="config-empty">
            <i class="iconfont icon-beijianleixing-"></i>
            <span>暂无组态</span>
          </div>
        </div>
        <div v-else-if="conName=='历史记录'" class="table-list table-history">
          <HistRecord :key="projectId" :projectIds="projectId" :configProEquipments="configProEquipment" @configLoadingBinds="configLoadingBind" :projectName="projectName"></HistRecord>
        </div>
        <div v-else-if="conName=='告警记录'" class="table-list">
          <el-table class="tables" :data="alarmTableData">
            <el-table-column prop="reportTime" label="上报时间" width="200"></el-table-column>
            <el-table-column prop="materialsCode" label="告警记录" width="200"></el-table-column>
            <el-table-column prop="name" label="名称" width="200"></el-table-column>
            <el-table-column prop="deviceTypeName" label="类型" width="200"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门" width="200"></el-table-column>
            <el-table-column prop="useCount" label="数量" width="200"></el-table-column>
          </el-table>
          <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
        </div>
        <div v-else-if="conName=='操作记录'" class="table-list">
          <OpRecrod :key="projectId" :configProEquipments="configProEquipment" @configLoadingBinds="configLoadingBind"></OpRecrod>
        </div>
      </div>
      <div class="btn-text-model" v-show="btnTextVisile" v-loading="btnTextLoading" element-loading-spinner="el-icon-loading">
        <div class="model-title">
          <p class="title-text">提示</p>
          <i class="el-icon-close" @click="btnClose"></i>
        </div>
        <div style="display:flex;align-items:center;margin-left:0px;">
          <i class="el-icon-warning" style="color:#E6A23C;font-size:20px;margin: 0 5px;"></i>
          <p class="btn-content-text">请确认是否点击按钮发送指令?</p>
        </div>
        <div class="model-fotter">
          <el-button type="primary" size="mini" @click="btnClose">关闭</el-button>
          <el-button type="primary" size="mini" @click="sendBtnInstruction">发送</el-button>
        </div>
      </div>
      <div class="edit-text-model" v-show="editTextVisile" v-loading="editTextLoading" element-loading-spinner="el-icon-loading">
        <div class="model-title">
          <p class="title-text">{{ inputTitle }} 指令下发</p>
          <i class="el-icon-close" @click="inputValueClose"></i>
        </div>
        <div class="model-input">
          <el-input v-model="inputValue" placeholder="请输入数值"></el-input>
        </div>
        <div class="model-fotter">
          <el-button type="primary" size="mini" @click="inputValueClose">关闭</el-button>
          <el-button type="primary" size="mini" @click="sendInputValue">发送</el-button>
        </div>
      </div>
      <Dloading v-if="configLoading"></Dloading>
    </div>
</template>
<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
import '@antv/x6-vue-shape'
import { Graph,Shape,FunctionExt,DataUri} from '@antv/x6';
import { handleDeviceNode } from '@/js/Graph/configDetails';
import { reportCache } from '@/js/Graph/reportCache';
import { waterLinearAnmi } from '@/js/Graph/waterLinearAnimation';
import { waterLevel } from '@/js/Graph/waterLevelAnimation';
import { calcRate } from '@/js/Graph/calcPixels';
import Dloading from '@/components/Dloading.vue';
import OpRecrod from './components/opRecord.vue';
import HistRecord from './components/histRecord.vue';
const data = {};
export default {
  components: {
    page,
    Dloading,
    OpRecrod,
    HistRecord
  },
  data() {
    return {
      loading: false,
      treeLoading: false,
      configLoading: false,
      row: null,
      authority: null,
      cellData: {},
      graph:'',
      type:'grid',
      selectCell:'',
      connectEdgeType:{  //连线方式
        connector: 'normal',
        router: {
          name: ''
        }
      },
      grid:{ // 网格设置
        size: 1,      // 网格大小 10px
        visible: false, // 渲染网格背景
        type: 'dot',
        args: {
          color: '#D0D0D0',
          thickness: 1,     // 网格线宽度/网格点大小
          factor: 10,
        },
      },
      wsdata: {},

      // 选项卡
      editableTabsValue: null,
      editableTabs: [],
      editTextVisile: false,
      inputTitle: null,
      inputValue: null,
      editTextObj: {},
      editTextLoading: false,

      btnTextVisile: false,
      btnTextLoading: false,
      btnTextObj: {},

      isModelMask:false,
      tabLabel: null,
      tabName: null,
      size: 50,
      page: 1,
      total: 0,
      configProEquipment: [],
      alarmTableData: [],
      titleArr: [],
      contentArr: [],
      formInline: {
        finishTime:[]
      },
      tempWsData:[],

      projectList: [],
      current: null,
      scaleIndex: 1,
      scaleCount: null,
      conName: null,
      projectName: null,
      showDeviceOfflines: []
    }
  },
  methods: {
    dayjs,
    findSubmit() {
      this.getRecordList()
    },

    // 获取历史记录列表
    async getRecordList() {
      await this.loadRecordTableTitle()
      await this.loadRecordTableContent()
    },

    // 获取历史记录表头
    loadRecordTableTitle() {
      return new Promise((resolve,reject)=>{
        this.editableTabs.forEach(editTab=>{
          if(this.editableTabsValue==editTab.name) {
            let indexOf = this.editableTabsValue.indexOf(' ')
            let configurationId = this.editableTabsValue.substring(0,indexOf)
            this.$ajax.post('reportConfigTitle', {
              configurationId: configurationId
            }).then(res => {
              this.titleArr = []
              res.data.forEach(v=>{
                v.titles.forEach(t=>{
                  this.titleArr.push(t)
                })
              })
              editTab.tableTitle = this.titleArr
              editTab.filterRecords = this.titleArr
              resolve()
            })
          }
        })
      })
    },

    // 获取历史记录内容
    loadRecordTableContent() {
      return new Promise((resolve,reject)=>{
        this.editableTabs.forEach(editTab=>{
          if(this.editableTabsValue==editTab.name) {
            // 获取昨天的时间戳
            let yDate = new Date()
            let year = yDate.getFullYear();
            let month = yDate.getMonth()+1;
            let date = yDate.getDate()-1;
            let yesterday = year+'-'+month+'-'+date
            let yesterdayTime = (new Date(new Date(yesterday).toLocaleDateString())).getTime();
            // 获取当天的时间戳
            let todayTime = (new Date(new Date().toLocaleDateString())).getTime();
            let dates = [] 
            if(editTab.finishTime){
              editTab.finishTime.forEach((i)=>{
                var date = new Date(i)
                dates.push(date.getTime()) 
              })
            }
            let indexOf = this.editableTabsValue.indexOf(' ')
            let configurationId = this.editableTabsValue.substring(0,indexOf)
            this.$ajax.post('reportConfigRecord', {
              configurationId: configurationId,
              startTime: dates.length>0?dates[0]:yesterdayTime,
              endTime: dates.length>0?dates[1]:todayTime,
              pageNum: editTab.page,
              pageSize: editTab.size, 
            }).then(res => {
              this.contentArr = []
              res.data.forEach(v=>{
                v.attributes.forEach((rItem,rIndex)=>{
                  if(this.contentArr.length>=this.size) {
                    this.contentArr.forEach((tItem,tIndex)=>{
                      if(rIndex==tIndex) {
                        rItem.forEach(vItem=>{
                          tItem[vItem.tag] = vItem.tagValue?vItem.tagValue:'-'
                        })
                      }
                    })
                  }else {
                    let obj = {}
                    rItem.forEach(vItem=>{
                      obj[vItem.tag] = vItem.tagValue?vItem.tagValue:'-'
                    })
                    this.contentArr.push(obj)
                  }
                })
              })
              editTab.tableContent = this.contentArr
              editTab.total = res.total
              resolve()
            })
          }
        })
      })
    },

    // Tag过滤筛选
    changeFilter(v) {
      this.getTableScroll(v)
    },

    getTableScroll(tag) {
      const contentTables = this.$refs
      this.$nextTick(()=>{
        for(let i in contentTables) {
          this.editableTabs.forEach(v=>{
            if(i==v.name&&v.identification=='historyRecord') {
              v.tableTitle.forEach((titleItem,titleIndex)=>{
                if(tag==titleItem.tag) {
                  contentTables[i].forEach(comp=>{
                    let positionJump = titleIndex * 200 
                    console.log(positionJump)
                    setTimeout(()=>{
                      comp.bodyWrapper.scrollLeft = positionJump
                    },300)
                  })
                }
              })
            }
          })
        }
      })
    },
    // 初始化画布
    initX6(configurationId){
      var _that = this
      this.graph = new Graph({
          container: document.getElementById('containerDetailChart'+configurationId),
          // width: '100%',
          height: '100vh',
          grid: _that.grid,
          panning: {
            enabled: false,//开启平移
            modifiers: 'ctrl',//如何其他操作有冲突,可用修饰参数触发
            eventTypes: ['leftMouseDown','rightMouseDown','mouseWheel'],//设置触发画布的拖拽行为,默认为['leftMouseDown']          
          },
          scroller: {
            enabled: true,
            pannable: true,
            pageVisible: false,
            pageBreak: false,
          },
          mousewheel: {
            enabled: true,//开启滚轮缩放
            global: false,
            // modifiers: ['ctrl','meta'],
          },
          resizing: { //调整节点宽高
            enabled: false,
            orthogonal:false,
          }, 
          interacting: {//节点拖动
            nodeMovable: false,//禁止节点拖动
            edgeLabelMovable: false,//禁止边拖动
          },
      });
      this.graph.fromJSON(data)
      this.graph.centerContent()
      this.graph.on('blank:click', ({ e }) =>{
        if(this.btnTextLoading==false) {
          this.btnTextVisile = false
        }
        this.editTextVisile = false
      })
      // 监听cell点击事件
      this.graph.on('cell:click', ({ cell }) => {
        this.type = cell.isNode() ? 'node' : 'edge'
        let cellData = cell.store.data.data
        this.btnTextVisile = false
        this.editTextVisile = false
        this.isModelMask = false
        if(this.authority=='operate') {
          // 如果是按钮
          if(cellData&&cellData.graphical.action==0) {
            this.btnTextObj = cellData
            this.btnTextVisile = true
            this.isModelMask = true
          }
          // 如果是开关
          if(cellData&&cellData.graphical.action==1) {
            this.btnTextObj = cellData
            this.btnTextVisile = true
            this.isModelMask = true
          }
          // 如果是外链
          if(cellData&&cellData.graphical.action==2) {
            this.row = cellData.graphical.configurationIdOutsideLink
            let inIs = this.editableTabs.find((v) => {
              return v.name == this.row
            })
            if(inIs) {
              this.editableTabsValue = this.row
              let tabTitle = this.editableTabs.find((ve) => {
                return ve.name == this.editableTabsValue
              })
              this.tabName = this.editableTabsValue
              this.tabLabel = tabTitle.title
            }else {
              this.loadDetailData()
            }
          }
          // 如果为编辑文本
          if(cellData&&cellData.graphicalType=='editText') {
            console.log(cellData)
            this.inputTitle = cellData.graphical.name
            this.inputValue = null
            this.editTextObj = cellData.graphical
            this.editTextObj.gatewayEid = cellData.gatewayEid
            this.editTextObj.eid = cellData.eid
            this.editTextVisile = true
            this.isModelMask = true
          }
        }
      })
      // 屏幕自适应
      window.addEventListener('resize', this.resize)
    },
    showPorts (ports, show) {
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = show ? 'visible' : 'hidden'
      }
    },
    // 计算屏幕缩放
    resize() {
      const configContainer = document.querySelectorAll('.config-container')
      calcRate(configContainer)
    },
    // 展开折叠项目
    projectToggle(item) {
      item.disable = !item.disable
    },
    // 选择指定组态
    selectConfig(val,item) {
      this.showDeviceOfflines = []
      this.projectName = item.name
      this.btnTextVisile = false
      this.btnTextLoading = false
      this.editTextVisile = false
      this.editTextLoading = false
      this.isModelMask = false

      this.projectId = val.projectId
      this.current = val.configurationId
      this.row = val.configurationId
      this.authority = val.authority
      this.conName = val.name
      if(this.graph) {
        this.graph.dispose()
      }
      if(val.name!='历史记录'&&val.name!='告警记录'&&val.name!='操作记录') {
        this.loadDetailData()
      }else if(val.name=='历史记录') {
        this.projectEquipmentList(val.projectIdHistory)
        this.projectId = val.projectIdHistory
      }else if(val.name=='告警记录') {

      }else if(val.name=='操作记录') {
        this.projectEquipmentList(val.projectIdOperate)
      }
    },
    // 监听操作记录接口列表数据更新
    configLoadingBind(e) {
      this.configLoading = e
    },
    // 获取项目列表
    loadConfigProject() {
      this.treeLoading = true
      this.$ajax.post('configProjectQuery',{}).then(res => {
        let arr = res.data
        if(arr.length==0) {
          this.treeLoading = false
        }
        arr.forEach(v=> {
          v.disable = false
          v.name = v.name
          this.loadClassifyList(v)
        })
        this.projectList = arr
      }).catch(err => {
        this.treeLoading = false
      })
    },
    // 获取项目下组态列表
    loadClassifyList(conObj) {
      this.$ajax.post('configurationQuery', {
        pageNum: this.page,
        pageSize: this.size,
        projectId: conObj.projectId,
      }).then(res => {
        conObj.authority = res.data[0].authority
        conObj.configList = res.data.reverse()
        // conObj.configList.forEach(v=>{
          if(conObj.name!='综合信息') {
            conObj.configList.push({
              authority: conObj.authority,
              configurationId: conObj.projectId+'history',
              coverFileUrl: "",
              describe: "",
              name: "历史记录",
              projectId: conObj.projectId+'history',
              projectIdHistory: conObj.projectId
            },{
              authority: conObj.authority,
              configurationId: conObj.projectId+'operate',
              coverFileUrl: "",
              describe: "",
              name: "操作记录",
              projectId: conObj.projectId+'operate',
              projectIdOperate: conObj.projectId
            })
          }
        // })
        // 告警记录未做, 暂时保留
        // {
        //   authority: conObj.authority,
        //   configurationId: conObj.projectId+'alarm',
        //   coverFileUrl: "",
        //   describe: "",
        //   name: "告警记录",
        //   projectId: conObj.projectId+'alarm',
        //   projectIdAlarm: conObj.projectId
        // },
        this.$forceUpdate()
        this.treeLoading = false
      }).catch(err=>{
        this.treeLoading = false
      })
    },
    // 组态详情接口
    loadDetailData() {
      this.configLoading = true
      this.$ajax.post('configurationDetail', {}, { configurationId: this.row }).then(res => {
        this.editableTabsValue = res.data.configurationId
        setTimeout(()=>{
          // 组态结构全局赋值
          this.initX6(res.data.configurationId)
          this.cellData = res.data
          // 将接口数据渲染至画布上
          this.graph.fromJSON(this.cellData.cells)
          // 屏幕自适应
          this.resize()
          // 获取最新缓存数据
          this.loadReportCache(this.cellData)
          // 隐藏链接桩port
          const container =  document.getElementById('containerDetailChart'+res.data.configurationId)
          const ports = container.querySelectorAll('.x6-port-body')
          this.showPorts(ports, false)
          // 获取所有画布上节点
          let allNodes = this.graph.getNodes()
          //循环画布上所有节点
          allNodes.forEach(v=>{
            if(v.store.data.data&&JSON.stringify(v.store.data.data.graphical)=='{}') {
              // v.attr('body/fill', 'none')
            }
            v.attr('text/fontFamily', 'SourceHanSerifCN-Bold')
            if(v.store.data.data&&v.store.data.data.withData==0) {
              if(v.store.data.data.graphicalType=='dynamicPicture') {
                v.attr('svgele/class', 'hideNode')
                if(v.store.data.data.graphical.customAnimations) {
                  v.store.data.data.graphical.customAnimations.forEach(anV=>{
                    if(anV.animation=='textChange') {
                      v.attr('body/class', 'hideNode')
                      v.attr('label/class', 'hideNode')
                      v.attr('image/class', 'hideNode')
                    }
                  })
                }
              }
            }
            if(v.store.data.data&&v.store.data.data.withData==1) {
              if(v.store.data.data.graphical.customAnimations) {
                v.store.data.data.graphical.customAnimations.forEach(anV=>{
                  if(anV.withoutData=='execute') {//默认执行动画
                    waterLinearAnmi(v)
                    v.attr('svgele/class', 'showNode')
                  }else if(anV.withoutData=='noExecute') {//默认不执行
                    v.attr('svgele/class', 'hideNode')
                  }
                  if(anV.animation=='waterLevel') {//普通节点液位动画
                    let numItemVal= Number(anV.waterLevel.defaultValue)
                    let maxVal= Number(anV.waterLevel.max)
                    let calculate = (numItemVal/maxVal)*100
                    //液位动画
                    waterLevel(v,calculate)
                  }
                })
              }
            }
          })
          this.connectWebsocket()
          this.handleList()
          this.configLoading = false
        },0)
      }).catch(err=>{
        this.configLoading = false
      })
    },
    // 点击按钮下发数据
    buttonPushData(gatewayEid,eid,downTag,downTagValue,upTag,upTagValue) {
      this.btnTextLoading = true
      setTimeout(()=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
      },500)
      this.$ajax.post("configButtonPushData", {
        gatewayEid: gatewayEid,
        eid: eid,
        downTag: downTag,
        downTagValue: downTagValue,
        upTag: upTag,
        upTagValue: upTagValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: this.row
      }).then((res) => {
        if(res.code!=0) {
          this.$message.error('下发失败');
        }
      }).catch(err=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
        this.$message.error(err.msg);
      })
    },
    // 点击开关下发数据
    switchPushData(gatewayEid,eid,tag,tagValue) {
      this.btnTextLoading = true
      setTimeout(()=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
      },500)
      this.$ajax.post("configSwitchPushData", {
        gatewayEid: gatewayEid,
        eid: eid,
        tag: tag,
        tagValue: tagValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: this.row
      }).then((res) => {
        if(res.code!=0) {
          this.$message.error('下发失败');
        }
      }).catch(err=>{
        this.btnTextVisile = false
        this.btnTextLoading = false
        this.isModelMask = false
        this.$message.error(err.msg);
      })
    },
    // 关闭按钮弹框
    btnClose() {
      this.btnTextVisile = false
      this.isModelMask = false
    },
    // 点击弹框确定按钮下发指令
    sendBtnInstruction() {
      console.log(this.btnTextObj)
      let gatewayEid = this.btnTextObj.gatewayEid
      let eid = this.btnTextObj.eid
      if(this.btnTextObj.graphical.action==0) {
        let downTag = this.btnTextObj.graphical.downTag
        let downTagValue = this.btnTextObj.graphical.downTagValue
        let upTag = this.btnTextObj.graphical.upTag
        let upTagValue = this.btnTextObj.graphical.upTagValue
        this.buttonPushData(gatewayEid,eid,downTag,downTagValue,upTag,upTagValue)
      }
      if(this.btnTextObj.graphical.action==1) {
        let pushTag = this.btnTextObj.graphical.pushTag
        let pushTagValue = this.btnTextObj.graphical.pushTagValue
        this.switchPushData(gatewayEid,eid,pushTag,pushTagValue)
      }
    },
    // 保留小数点后N为,并补零
    changeDecimal(number,bitNum) {
      let f_x = parseFloat(number);
      if(isNaN(f_x)) {
        return 0;
      }
      let s_x = number.toString();
      let pos_decimal =s_x.indexOf('.');
      if(pos_decimal<0) {
        pos_decimal = s_x.length;
        if(bitNum!=0) {
          s_x += '.';
        }else {
          return s_x;
        }
      }
      if(s_x.length <= pos_decimal + bitNum) {
        while(s_x.length <= pos_decimal + bitNum) {
          s_x += '0';
        }
      }else {
        s_x = s_x.substring(0,pos_decimal + bitNum + 1)
      }
      return s_x;
    },
    // 关闭编辑文本弹框
    inputValueClose() {
      this.editTextVisile = false
      this.isModelMask = false
    },
    // 发送指令
    sendInputValue() {
      let max = Number(this.editTextObj.max)
      let min = Number(this.editTextObj.min)
      let pointBehindNum = Number(this.editTextObj.pointBehindNum)
      if(!this.inputValue) {
        return this.$message.error('请输入下发数值')
      }
      if(this.inputValue<min||this.inputValue>max) {
        return this.$message.error('请输入设定范围的数值')
      }
     
      // let tagValue = this.changeDecimal(this.inputValue,pointBehindNum)
      this.editTextLoading = true
      setTimeout(()=>{
        this.editTextVisile = false
        this.editTextLoading = false
        this.isModelMask = false
      },500)
      this.$ajax.post("configOutputPushData",{
        gatewayEid: this.editTextObj.gatewayEid,
        eid: this.editTextObj.eid,
        tag: this.editTextObj.tag,
        tagValue: this.inputValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: this.row
      }).then(res=>{
        if(res.code!=0) {
          this.$message.error('发送失败');
        }
      }).catch(err=>{
        if(err.code==1) {
          this.editTextVisile = false
          this.editTextLoading = false
          this.isModelMask = false
          this.$message.error(err.msg);
        }
      })
    },
    // 连接websocket
    connectWebsocket() {
      // 客户端收到服务的返回的数据
      this.$globalWs.ws.onmessage = e => {
        if(e.data.indexOf('pong') > -1) {
          // 心跳回复——心跳检测重置
          // 收到心跳检测回复就说明连接正常
          console.log("收到心跳检测回复");
          // 心跳检测重置
          this.$globalWs.heartCheck.reset().start(this.$globalWs.ws);
        }else {
          let data = JSON.parse(e.data)
          let message = JSON.parse(data.message)
          // 获取所有画布上节点
          let allNodes = this.graph.getNodes()
          let find = allNodes.some(v=>v.store.data.data&&message.subEid==v.store.data.data.eid)
          if(find) {
            this.tempWsData.push(data)
          }
        }
      }
    },
    // 通过缓存数组处理websocket卡顿
    handleList() {
      this.wsInterval = setInterval(()=>{
        if(this.tempWsData.length==0) return
        if(this.tempWsData.length>10) {
          this.tempWsData = []
          return
        }
        let dmessage = JSON.parse(this.tempWsData[0].message)
        // 推送消息为组态数据
        if(this.tempWsData[0].messageType=='configurationMessage') {
          handleDeviceNode(this.graph, dmessage, this.editableTabsValue)
        }
        this.tempWsData.shift()
      },100)
    },
    // 获取最新组态缓存数据
    loadReportCache(cellData) {
      let cellEdids = []
      let eids = []
      let resultEids = []
      for(let i=0;i<cellData.cells.length;i++) {
        let data = cellData.cells[i].data
        if(data&&data.eid) {
          let inIs = eids.find(function(e) { return e.eid == data.eid })
          if (!inIs) { 
            cellEdids.push(data.eid)
            eids.push({
              eid: data.eid,
              equipmentName: data.equipmentName,
            }) 
          }
        }
      }
      this.$ajax.post('configFirstReportCache', {
        eids: cellEdids
      }).then(res => {
        res.data.forEach(wsdata=>{
          resultEids.push({
            eid: wsdata.eid,
            equipmentName: wsdata.equipmentName,
          })
          reportCache(this.graph, wsdata, this.editableTabsValue)
        })
        let deviceOfflines = [...eids].filter(item=>[...resultEids].every(obj=>obj.eid!=item.eid))
        this.showDeviceOfflines = deviceOfflines
      })
    },
    // 查询组态项目下的所有设备
    projectEquipmentList(projectId) {
      this.$ajax.post('projectEquipmentQuery', {
        projectId: projectId,
      }).then(res => {
        this.configProEquipment = res.data
      })
    },
    pageChange(val,item) {
      item.page = val
      sessionStorage.setItem('currentPage', val)
      this.getRecordList()
    },
    sizeChange(val,item) {
      item.size = val
      sessionStorage.setItem('currentSize', val)
      this.getRecordList()
    },
  },
  
  mounted(){
    // if (this.$route.params.row == null) {
    //   this.$router.push('/iotConfigExhib')
    // } else {
    //   this.row = this.$route.params.row
      this.loadConfigProject()
    // }
  },
  destroyed() {
    clearInterval(this.wsInterval)
  },
};
</script>
<style lang="less" scoped>
#home .el-main > div > div[data-v-fae5bece] {
  padding: 0;
}
.container_warp {
  height: 100%;
  background: #0e1821;
  font-family: 'SourceHanSerifCN-Bold';
  .warp-content {
    display:flex;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .content-menu {
    height: 87.8vh;
    min-width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    .menu-list {
      overflow-y: auto;
      padding-bottom: 10px;
    }
  }
  .content-config {
    flex: 7;
  }
  .tab-pane-bg {
    background: #0e1821;
    height: 85.8vh;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }
  .table-list {
    width: 100%;
    height: calc(100vh - 12vh);
    overflow: auto;
    .tables {
      width: 100%;
      height: calc(100vh - 19vh);
      overflow: auto;
    }
  }
  .table-history {
    .tables {
      width: 100%;
      height: calc(100vh - 26vh);
      overflow: auto;
    }
  }
}
.tree-menu {
  font-size: 14px;
  cursor: pointer;
}
.fix-offline-device {
  font-size: 12px;
  background: rgba(151, 149, 149, 0.177);
  padding: 10px;
  border-radius: 6px;
  box-sizing: border-box;
  color: #c6c6c6;
  user-select: none;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  .offline-device-count {
    display: flex;
    justify-content: space-between;
    
    .device-count-value {
      background: #ff47562d;
      color: #ff4757;
      border-radius: 20px;
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 15px;
    }
  }
  .offline-device-list {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgba(151, 151, 151, 0.256);
    .device-list-name {
      margin-bottom: 10px;
    }
    .device-list-value {
      width: 230px;
      display: flex;
      flex-wrap: wrap;
      .device-list-item {
        background: #ff47562d;
        color: #ff4757;
        border-radius: 3px;
        padding: 2px;
        margin: 5px;
      }
    }
  }
}
.tree-menu-title {
  line-height: 40px;
  padding-left: 10px;
  transition: all .4s ease;
  color: #FFF;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-triangle {
    margin-right: 5px;
    transition: all .4s ease;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid #FFF;
    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-radius: 3px;
  }
  .arrow {
    transform: rotate(90deg);
  }
  .unarrow {
    transform: rotate(0deg);
  }
  .flag {
    display: inline-block;
    border-radius: 4px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    padding: 0 5px;
    margin-right: 5px;
  }
  .flag-allow {
    background-color: rgba(69, 170, 242, 0.15);
    border-color: rgba(69, 170, 242, 0.15);
    color: rgb(69, 170, 242);
  }
  .flag-onlyread {
    background-color: rgba(178, 190, 195, 0.15);
    border-color: rgba(178, 190, 195, 0.15);
    color: #b2bec3;
  }
}
.tree-menu-title:hover {
  border-radius: 5px;
}
.tree-config {
  line-height: 40px;
}
.config-title {
  padding-left: 20px;
  color: #89A3BE;
  user-select: none;
  border-radius: 5px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  // background: #409EFF;
  background-image: linear-gradient(to right, rgba(93, 195, 238, 1), rgba(93, 195, 238, 0));
  color: #FFF;
}
.config-empty {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 14px;
  color: #75787e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  i {
    font-size: 150px;
    margin-bottom: 20px;
  }
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 40px;
  color: #3f6bdc;
}
/deep/.x6-node {
  cursor: pointer;
}
/deep/::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
/deep/::-webkit-scrollbar-thumb {
  border-radius: 3px; 
  background-color: #5b5b5b;
}
/deep/.el-dropdown-menu {
    background-color: #48484E;
    border: 1px solid #363949;
    box-shadow: 0 2px 12px 0 rgba(102, 102, 102, 0.3);
}
/deep/.el-dropdown-menu__item {
    color: #FFF;
}
/deep/.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #49555d;
    color: #66b1ff;
}
/deep/.el-form-item__label {
    color: #cfd6de;
}
// 表格组件样式
/deep/.el-table {
    background-color: #0e1821;
}
/deep/.el-table__header {
    width: 100% !important;
}
/deep/.el-table__body {
    width: 100% !important;
}
/deep/.el-table::before {
    height: 0;
}
/deep/.el-table__header th {
    color: rgba(255, 255, 255, 0.7);
    background-color: #2d3041 !important;
    font-weight: normal;
    font-size: 13px;
    border: 0;
}
/deep/.el-table th:nth-child(1) {
    border-top-left-radius: 0px;
}
/deep/.el-table th:nth-last-child(2) {
    border-top-right-radius: 0px;
}
/deep/.el-table td {
    border-bottom: 1px solid rgba(45, 45, 48, 1) !important;
    color: rgba(255, 255, 255, 1);
    background-color: #0e1821;
}
/deep/.el-table tbody tr:hover > td {
    background-color: #2d3041 !important;
}
/deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: 0 !important;
}
/deep/.el-table__empty-block {
    width: 100% !important;
}
// 分页组件样式
/deep/.el-pagination .el-pagination__sizes .el-input__inner{
    color: rgba(255, 255, 255, 0.7);
    background-color: #2D3041;
    border: 0;
}
/deep/.el-pagination.is-background .btn-next {
    background-color: #2D3041;
    color: rgba(255, 255, 255, 0.7);
}
/deep/.el-pagination.is-background .btn-prev {
    background-color: #2D3041;
    color: rgba(255, 255, 255, 0.7);
}
/deep/.el-pagination.is-background .el-pager li {
    background-color: #2D3041;
    color: rgba(255, 255, 255, 0.7);
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: rgba(255, 255, 255, 1);
    background-color: #8e92a1;
}
/deep/.el-pagination__editor .el-input__inner {
    color: rgba(255, 255, 255, 0.7);
    background-color: #2D3041;
    border: 0;
}
// 下拉组件样式
/deep/.el-select-dropdown {
    background-color: #2D3041;
    border: 1px solid #4b4e5c;
}
/deep/.el-select-dropdown__item {
    color: rgba(255, 255, 255, 0.7);
}
/deep/.el-popper[x-placement^=top] .popper__arrow{
    border-top-color: #4b4e5c;
}
/deep/.el-popper[x-placement^=top] .popper__arrow::after {
    border-top-color: #2D3041;
}
/deep/.el-popper[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #4b4e5c;
}
/deep/.el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #2D3041;
}
/deep/.el-select-dropdown__item.hover {
    background-color: #555862;
}
/deep/.el-select-dropdown__item.selected {
    color: rgba(255, 255, 255, 1);
    background-color: #8e92a1;
}
/deep/.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
    background-color: #4B4E5C;
}
/deep/.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    background-color: #4B4E5C;
}
/deep/.el-tag.el-tag--info {
    background-color: #676b7b;
    border-color: #676b7b;
    color: #f4f4f5;
}
// 输入框组件样式
/deep/.search .el-input__inner {
    color: rgba(255, 255, 255, 1);
    background-color: #2D3041;
    border: 0;
}
/deep/.search input {
    color: rgba(255, 255, 255, 1);
    background-color: #2D3041;
}
/deep/.search .el-range-separator {
    color: #606266;
}
/deep/.search .el-range-input {
    color: rgba(255, 255, 255, 0.7);
}
// 区间日期组件样式
/deep/.el-picker-panel {
    background-color: #2D3041;
    border: 1px solid #4b4e5c;
    color: #FFF;
}
/deep/.el-date-table td.next-month {
    color: #75787d;
}
/deep/.el-date-table td.prev-month {
    color: #75787d;
}
/deep/.el-date-table th {
    color: #FFF;
    border-bottom: solid 1px #4b4e5c;
}
/deep/.el-date-range-picker__content.is-left {
    border-right: 1px solid #606371;
}
/deep/.el-picker-panel__icon-btn {
    color: rgba(255, 255, 255, 0.7);
}
/deep/.el-date-table td.in-range div {
    background-color: #494b4e;
}
/deep/.el-date-table td.in-range div:hover {
    background-color: #494b4e;
}
/deep/.el-date-table td.in-range div:hover {
    background-color: #494b4e;
}
// 单日期组件样式
/deep/.el-date-table td.disabled div {
    background-color: #4b4e5c;
    color: #C0C4CC;
}
/deep/.el-date-picker__header-label {
    color: #FFF;
}
// 时间选择
/deep/.el-time-panel {
    background-color: #2D3041;
    border: 1px solid #4b4e5c;
    color: #FFF;
}
/deep/.el-time-spinner__item.active:not(.disabled) {
    color: #FFF;
}
/deep/.el-time-spinner__item {
    color: rgba(255, 255, 255, 0.6);
}
/deep/.el-time-spinner__item:hover:not(.disabled):not(.active) {
    background-color: #555862;
}
/deep/.el-time-panel__btn {
    color: #FFF;
}
/deep/.el-time-panel__footer {
    border-top: 1px solid #4b4e5c;
}
/deep/.el-time-panel__content::after {
    border-top: 1px solid #4b4e5c;
    border-bottom: 1px solid #4b4e5c;
}
/deep/.el-time-panel__content::before {
    border-top: 1px solid #4b4e5c;
    border-bottom: 1px solid #4b4e5c;
}
</style>

<style lang="less">
  @import '../../css/iotConfigExhib/detail.less';
</style>