<template>
  <div style="padding-top: 10px;">
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="日期">
            <el-date-picker
              size="small"
              v-model="formInline.finishTime"
              type="daterange"
              @change="changeDate"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="属性筛选">
            <el-select size="small" v-model="equipment" value-key="eidTag" clearable multiple collapse-tags placeholder="请选择属性" @change="changeFilterEquipment">
              <el-option
                v-for="item in titleArr"
                :label="item.tag+'('+item.describe+')'"
                :value="item"
                :key="item.eid+item.tag"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="exportReport">导出报表</el-button>
          </el-form-item>
        </el-form>
      </div>
        <el-table ref="tables" class="tables" :data="tableContent" height="74vh">
          <el-table-column prop="reportTime" label="上报时间" width="240">
            <template slot-scope="scope">
              {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column :class="tItem.tag" :width="tableTitle.length<9?'':'240'" v-for="tItem in tableTitle" :key="tItem.eid+tItem.tag" :label="`${tItem.tag}(${tItem.describe})`" :prop="tItem.tag"></el-table-column>
        </el-table>
      <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  props: {
    configProEquipments: {
      type: Array
    },
    projectIds: {
      type: String
    },
    projectName: {
      type: String
    }
  },
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 100,
      page: 1,
      total: 0,
      formInline: {},
      row: {},
      equipment: null,
      titleArr: [],
      contentArr: [],
      tableTitle: [],
      tableContent: [],
      filterRecords: [],
      filter: '',

      staffId: 0,
      roleId: 0,
      projectId: null,
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d >= n;
        }
      },
    }
  },
  watch: {
    configProEquipments: {
      handler (newVal, oldVal) {
        this.configProEquipments = newVal
        this.projectId = this.projectIds
        newVal && newVal.length!=0 && this.getRecordList() && this.loadRecordTableContent()
      }
    }
  },
  methods: {
    dayjs,
    // 时分秒转 秒
    dateToSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + strArr[2]
      return second
    },
    // 日期选择
    changeDate(e) {
      this.page = 1
      this.loadRecordTableContent()
    },
    // 属性筛选
    async changeFilterEquipment(e) {
      this.page = 1
      // 表头过滤
      let arrTitle = []
      this.titleArr.forEach(v=>{
        e.forEach(es=>{
          if((v.eid+v.tag)==(es.eid+es.tag)) {
            arrTitle.push(v)
          }
        })
      })
      this.tableTitle = arrTitle
      await this.loadRecordTableContent()
    },
    // 导出记录报表
    exportReport() {
      if(!this.formInline.finishTime) return this.$message.error('请选择日期')
      if(this.equipment.length==0) return this.$message.error('请选择属性')
      return
      this.$emit('configLoadingBinds',true)
      this.$ajax.exportRecord("dingbianExport", {
        date: this.formInline.date,
        time: this.formInline.time?this.dateToSecond(this.formInline.time):null,
        tags: this.equipment
      }).then((res) => {
        const blob = new Blob([res])
        let customFileName = this.projectName
        const fileName = customFileName + '组态历史记录.xls';
        const linkNode = document.createElement('a');
        linkNode.download = fileName;
        linkNode.style.display = 'none';
        linkNode.href = URL.createObjectURL(blob);
        document.body.appendChild(linkNode);
        linkNode.click();
        URL.revokeObjectURL(linkNode.href);
        document.body.removeChild(linkNode);
        this.$emit('configLoadingBinds',false)
        this.$message.success('导出成功')
      }).catch(err=>{
        this.$emit('configLoadingBinds',false)
        this.$message.error('导出失败')
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadRecordTableContent()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadRecordTableContent()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 100
    },
    // 获取历史记录列表
    async getRecordList() {
      await this.loadRecordTableTitle()
    },
    // 获取历史记录表头
    loadRecordTableTitle() {
      return new Promise((resolve,reject)=>{
        this.$emit('configLoadingBinds',true)
        this.$ajax.post('reportConfigTitle', {
          projectId: this.projectId,
        }).then(res => {
          this.titleArr = []
          res.data.forEach(v=>{
            v.tagNodes.forEach(t=>{
              this.titleArr.push({
                eid: v.eid,
                equipmentName: v.equipmentName,
                tag: t.tag,
                describe: t.describe,
                eidTag: v.eid+t.tag,
              })
            })
          })
          this.$emit('configLoadingBinds',false)
          resolve()
        }).catch(err => {
          this.$emit('configLoadingBinds',false)
        })
      })
    },
    // 获取历史记录内容
    loadRecordTableContent() {
      return new Promise((resolve,reject)=>{
        this.$emit('configLoadingBinds',true)
        let obj = {}
        let queryObj = {
          projectId: this.projectId,
          equipmentFunctions: [],
          pageNum: this.page,
          pageSize: this.size
        }
        let dates = [] 
        if(this.formInline.finishTime){
          this.formInline.finishTime.forEach((i)=>{
            var date = new Date(i)
            dates.push(date.getTime()) 
          })
          queryObj.startTime = dates[0]
          queryObj.endTime = dates[1]
        }
        this.tableTitle.forEach(v=>{
          let equipmentObj = {
            eid: v.eid,
            tags: []
          }
          if(!obj[v.eid]) {
            equipmentObj.tags.push(v.tag)
            queryObj.equipmentFunctions.push(equipmentObj)
            obj[v.eid] = v
          }else {
            queryObj.equipmentFunctions.forEach(e=>{
              if(e.eid==v.eid) {
                e.tags.push(v.tag)
              }
            })
          }
        })
        this.$ajax.post('reportConfigRecord', queryObj).then(res => {
          this.contentArr = []
          res.data.forEach(v=>{
            let obj = {
              reportTime: v.reportTime
            }
            for(let rIndex in v.attributes) {
              obj[rIndex] = v.attributes[rIndex]&&v.attributes[rIndex]!='null'?v.attributes[rIndex]:'-'
            }
            this.contentArr.push(obj)
            // v.attributes.forEach((rItem,rIndex)=>{
            //   if(this.contentArr.length>=this.size) {
            //     this.contentArr.forEach((tItem,tIndex)=>{
            //       if(rIndex==tIndex) {
            //         rItem.forEach(vItem=>{
            //           tItem[vItem.tag] = vItem.tagValue?vItem.tagValue:'-'
            //         })
            //       }
            //     })
            //   }else {
            //     let obj = {}
            //     rItem.forEach(vItem=>{
            //       obj[vItem.tag] = vItem.tagValue?vItem.tagValue:'-'
            //     })
            //     this.contentArr.push(obj)
            //   }
            // })
          })
          this.tableContent = this.contentArr
          this.total = res.total
          this.$emit('configLoadingBinds',false)
          resolve()
        }).catch(err => {
          this.$emit('configLoadingBinds',false)
        })
      })
    },
    // Tag过滤筛选
    changeFilter(v) {
      this.getTableScroll(v)
    },
    getTableScroll(params) {
      const contentTables = this.$refs
      this.$nextTick(()=>{
        this.tableTitle.forEach((titleItem,titleIndex)=>{
          if(params==titleItem.tag) {
            contentTables[i].forEach(comp=>{
              let positionJump = titleIndex * 200
              setTimeout(()=>{
                comp.bodyWrapper.scrollLeft = positionJump
              },300)
            })
          }
        })
      })
    }
  },
  mounted() {
    sessionStorage.removeItem('currentSize')
    this.getLastPage()
    this.getSize()
  }
}
</script>

<style lang="less" scoped>
/deep/.el-form--inline .el-form-item__content {
  vertical-align: middle;
}
.el-form-item {
  margin-bottom: 10px;
  margin-left: 10px;
}
/deep/.el-table .cell{
  height: 23px !important;
}
/deep/.search .el-select .el-input__inner{
  padding: 0 77px;
  text-align: center;
}
@media screen and (max-width:1000px) {
  .tables {
    height: calc(100vh - 46vh) !important;
  }
}
</style>